import React, { useEffect, useRef, useState } from 'react';
import I18n from 'i18n';
import Tooltip from './tooltip';
import axios from 'axios';
import Notificator from '../libraries/notificator';
import { SourceCategoryItem } from './SourceCategoryLicensingList';

interface Props {
  item: SourceCategoryItem;
}

export default function SetUpLicensing({ item }: Props) {
  const [checked, setChecked] = useState(item.enabled);
  const isErrorRef = useRef(false);
  const [initialViewLoaded, setInitialViewLoaded] = useState(false);
  const [isUpdateOngoing, setUpdateOngoing] = useState(false);

  useEffect(() => {
    setInitialViewLoaded(true);
  }, []);

  useEffect(() => {
    if (initialViewLoaded && !isErrorRef.current) {
      setUpdateOngoing(true);
      axios
        .put('/api/v3/licensing_settings/update', {
          id: item.id,
          enabled: checked,
        })
        .then(function () {
          setUpdateOngoing(false);
          item.enabled = checked;
        })
        .catch(function (error) {
          setChecked((prevState) => !prevState);
          setUpdateOngoing(false);
          Notificator.showErrorNotification(error.message);
          isErrorRef.current = true;
        });
    } else {
      isErrorRef.current = false;
    }
  }, [checked]);

  function handleLicensingClick() {
    setChecked((prevState) => !prevState);
  }

  return (
    <div className="lightened-container lifted-container pts phl pbl">
      <div className="mbl">
        <h1 className="heading-xxlarge">
          {I18n.t('webapp.settings.licensing.setUp_header')}
        </h1>
      </div>
      <div className="button-group button-group--checkbox button-group--vertical mbm">
        <button
          type="button"
          className={
            checked ? 'button-group__item is-active' : 'button-group__item'
          }
          disabled={isUpdateOngoing ? true : false}
          onClick={handleLicensingClick}>
          {I18n.t('webapp.settings.licensing.setUp')}
          <Tooltip
            badge={true}
            tooltipBody={I18n.t(
              'webapp.settings.licensing.print_badge_tooltip'
            )}
          />
        </button>
      </div>
    </div>
  );
}
