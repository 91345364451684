import * as React from "react";
import I18n from "i18n";
import * as _ from "underscore";
import Validations from "libraries/validations";
import pusherWrapper from 'models/pusherWrapper'
import { ReaderNotificationConfigEmailForm } from "./ReaderNotificationConfigEmailForm";

const EMAIL_API_URL = "/api/v3/reader_configurations/511/email_settings";

const SenderDefaultValues = {
  email: function () {
    return I18n.t(
      "webapp.reader_admin.edit.notification_configuration.sender.default_email"
    );
  },
  name: function () {
    return I18n.t(
      "webapp.reader_admin.edit.notification_configuration.sender.default_name"
    );
  },
  warningContent: function () {
    return I18n.t(
      "webapp.reader_admin.edit.notification_configuration.sender.warning_content"
    );
  },
};

interface IProps {
  hidden: boolean;
}

interface IState {
  replyToEmail: string;
  replyToName: string;
  fromEmail: string;
  fromName: string;
  replyToEmailValid: boolean;
  fromEmailValid: boolean;
  fromEmailStatus: "new" | "checking" | "verified" | "active" | null;
  dnsStatus: string;
  version: string;
}

class ReaderNotificationConfigurationSenderFormView extends React.Component<
  IProps,
  IState
> {
  private pusherChannel: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      replyToEmail: "",
      replyToName: "",
      fromEmail: "",
      fromName: "",
      replyToEmailValid: true,
      fromEmailValid: true,
      fromEmailStatus: null,
      dnsStatus: "",
      version: "",
    };
  }

  componentDidMount() {
    this.fetchEmailSettings();

    pusherWrapper.on("reader_email_settings_change", this.handlePusherEvent);
  }

  handlePusherEvent = (data: any) => {
    console.log("Pusher event received:", data);
    this.setState({
      version: data.version || "",
      fromName: data.from_name || "",
      fromEmail: data.from_email || "",
      replyToName: data.replyto_name || "",
      replyToEmail: data.replyto_email || "",
      fromEmailStatus: data.status || null,
      dnsStatus: data.dns_zone || "",
    });
  };

  async fetchEmailSettings() {
    try {
      const response = await fetch(EMAIL_API_URL, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      });
      const data = await response.json();
      this.setState({
        version: data.version || "",
        fromName: data.from_name || "",
        fromEmail: data.from_email || "",
        replyToName: data.replyto_name || "",
        replyToEmail: data.replyto_email || "",
        fromEmailStatus: data.status || null,
        dnsStatus: data.dns_zone || "",
      });
    } catch (error) {
      console.error("Failed to fetch email settings:", error);
    }
  }

  async updateEmailSettings(operation?: string) {
    const { fromName, fromEmail, replyToName, replyToEmail, version } =
      this.state;

    if (!operation) {
      operation = version === "" ? "create" : "update";
    }

    try {
      const response = await fetch(EMAIL_API_URL, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          version,
          from_name: fromName,
          from_email: fromEmail,
          replyto_name: replyToName,
          replyto_email: replyToEmail,
          operation,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        this.setState({ version: responseData.version || "" });
      }
    } catch (error) {
      console.error("Failed to update email settings:", error);
    }
  }

  validateEmail(field: "fromEmail" | "replyToEmail") {
    const isValid = Validations.isEmailValid(this.state[field]);
    this.setState(
      { [`${field}Valid`]: isValid } as unknown as Pick<IState, keyof IState>,
      () => {
        if (isValid) {
          this.updateEmailSettings();
        }
      }
    );
  }

  handleChange = (
    field: "fromEmail" | "fromName" | "replyToEmail" | "replyToName",
    value: string
  ) => {
    const update: Partial<IState> = { [field]: value };
    this.setState(update as Pick<IState, keyof IState>);
  };

  handleBlur = (
    field: "fromEmail" | "fromName" | "replyToEmail" | "replyToName"
  ) => {
    this.validateEmail(field as "fromEmail" | "replyToEmail");
    this.updateEmailSettings();
  };

  handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    field: "fromEmail" | "fromName" | "replyToEmail" | "replyToName"
  ) => {
    if (event.key === "Enter") {
      if (field === "fromEmail" && this.state.fromEmailStatus === "new") {
        this.updateEmailSettings("Resend");
      } else {
        this.updateEmailSettings();
      }
    }
  };

  render() {
    if (this.props.hidden) return null;

    return (
      <div id="notification-sender-options">
        <div className="form-item mbm">
          <p className="text--grey">
            <strong>
              {I18n.t(
                "webapp.reader_admin.edit.notification_configuration.sender.warning_header"
              )}
            </strong>{" "}
            {SenderDefaultValues.warningContent()}
          </p>
        </div>
        <form>
          <ReaderNotificationConfigEmailForm
            senderNameLabel={I18n.t(
              "webapp.reader_admin.edit.notification_configuration.sender.from_sender_name_label"
            )}
            emailLabel={I18n.t(
              "webapp.reader_admin.edit.notification_configuration.sender.from_email_label"
            )}
            emailValue={this.state.fromEmail}
            senderValue={this.state.fromName}
            fromEmailStatus={this.state.fromEmailStatus}
            onEmailChange={(value) => this.handleChange("fromEmail", value)}
            onSenderChange={(value) => this.handleChange("fromName", value)}
            onEmailBlur={() => this.handleBlur("fromEmail")}
            onSenderBlur={() => this.handleBlur("fromName")}
            onEmailKeyPress={(event) => this.handleKeyPress(event, "fromEmail")}
            onSenderKeyPress={(event) => this.handleKeyPress(event, "fromName")}
            isFromEmail={true}
            emailValid={this.state.fromEmailValid}
          />
          <ReaderNotificationConfigEmailForm
            senderNameLabel={I18n.t(
              "webapp.reader_admin.edit.notification_configuration.sender.sender_name_label"
            )}
            emailLabel={I18n.t(
              "webapp.reader_admin.edit.notification_configuration.sender.email_label"
            )}
            emailValue={this.state.replyToEmail}
            senderValue={this.state.replyToName}
            onEmailChange={(value) => this.handleChange("replyToEmail", value)}
            onSenderChange={(value) => this.handleChange("replyToName", value)}
            onEmailBlur={() => this.handleBlur("replyToEmail")}
            onSenderBlur={() => this.handleBlur("replyToName")}
            onEmailKeyPress={(event) =>
              this.handleKeyPress(event, "replyToEmail")
            }
            onSenderKeyPress={(event) =>
              this.handleKeyPress(event, "replyToName")
            }
            emailValid={this.state.replyToEmailValid}
          />
        </form>
      </div>
    );
  }
}

export { SenderDefaultValues, ReaderNotificationConfigurationSenderFormView };
