import React, { useState } from "react";
import Tooltip from "../../components/tooltip";
import I18n from "i18n";

interface Props {
  senderNameLabel: string;
  emailLabel: string;
  emailValue: string;
  senderValue: string;
  fromEmailStatus?: "new" | "checking" | "verified" | "active" | null;
  onEmailChange: (value: string) => void;
  onSenderChange: (value: string) => void;
  onEmailBlur: () => void;
  onSenderBlur: () => void;
  onEmailKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSenderKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isFromEmail?: boolean;
  emailValid: boolean;
}

export function ReaderNotificationConfigEmailForm({
  senderNameLabel,
  emailLabel,
  emailValue,
  senderValue,
  fromEmailStatus,
  onEmailChange,
  onSenderChange,
  onEmailBlur,
  onSenderBlur,
  onEmailKeyPress,
  onSenderKeyPress,
  isFromEmail,
  emailValid,
}: Props) {
  const emailIconMap = {
    new: {
      icon: <i className="icon-arrow-up"></i>,
      tooltip: "Email is newly added.",
    },
    checking: {
      icon: <i className="legacy-icon-recover-dark"></i>,
      tooltip: "Checking email validity.",
    },
    verified: {
      icon: <i className="legacy-icon-ok-dark"></i>,
      tooltip: "Email is verified.",
    },
    active: {
      icon: <i className="icon-dot-filled" style={{ color: "#99BB33" }}></i>,
      tooltip: "Email is active.",
    },
  };

  const emailIcon = fromEmailStatus ? emailIconMap[fromEmailStatus] : null;
    // const emailIcon = emailIconMap.Verified;
  const [showDropdown, setShowDropdown] = useState(false);
  const emailError = I18n.t(
    "webapp.settings.users.user_details.validations.email_invalid"
  );

  const emailErrorClasses = ["input-group__feedback", "is-erroneous"];
  if (emailValid) {
    emailErrorClasses.push("hidden");
  }

  return (
    <div className="row">
      <div className="col1of2">
        <div className="form-item--inline mbs" style={{ width: "80%" }}>
          <label className="form-item__label">{senderNameLabel}</label>
          <input
            type="text"
            className="text-input text-input--block"
            value={senderValue}
            onChange={(e) => onSenderChange(e.target.value)}
            onBlur={onSenderBlur}
            onKeyDown={onSenderKeyPress}
          />
        </div>
      </div>
      <div className="col1of2">
        <div className="form-item--inline mbs" style={{ width: "80%" }}>
          <label className="form-item__label">{emailLabel}</label>
          <div style={{ position: "relative" }}>
            <div className="form-item__input" style={{ flexGrow: 1 }}>
              <input
                type="email"
                className="text-input text-input--block"
                value={emailValue}
                onChange={(e) => onEmailChange(e.target.value)}
                onBlur={onEmailBlur}
                onKeyDown={onEmailKeyPress}
              />
              <div className={emailErrorClasses.join(" ")}>
                <i className="icon-info-2" />
                <span>{emailError}</span>
              </div>
              {emailIcon && isFromEmail && (
                <span>
                  <Tooltip tooltipBody={emailIcon.tooltip}>
                    {emailIcon.icon}
                  </Tooltip>
                </span>
              )}
            </div>
            {isFromEmail && (
              <div style={{ position: "absolute", top: "10%", left: "100%" }}>
                <span
                  style={{ marginLeft: "10px", position: "relative" }}
                  onClick={() => {
                    console.log("edit");
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <i className="legacy-icon-edit-dark"></i>
                </span>
                {showDropdown && fromEmailFeature()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function fromEmailFeature() {
  return (
    <ul
      className="dropdown__menu"
      style={{
        display: "block",
        position: "absolute",
        top: "120%",
        left: "-250%",
        zIndex: 1000,
        marginTop: "5px",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <li className="dropdown__item">
        <a className="dropdown__link dropdown__link--disabled">
          Zonefile kopieren
        </a>
      </li>
      <li className="dropdown__item">
        <a className="dropdown__link">DNS-Status prüfen</a>
      </li>
      <li className="dropdown__item">
        <a className="dropdown__link dropdown__link--disabled">
          Adresse löschen
        </a>
      </li>
    </ul>
  );
}
