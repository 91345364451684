import * as React from "react";
import * as jQuery from "jquery";
import "jquery.qtip2";
import I18n from 'i18n';

interface ITooltipProps {
  heading?: string;
  tooltipHeadline?: string;
  tooltipBody: JSX.Element | string;
  positionMy?: string;
  positionAt?: string;
  badge?: boolean;
  children?: any;
}

const fadeInDelay = 250;
const fadeInDuration = 500;
const fadeOutDelay = 750;
const fadeOutDuration = 500;
const opaqueAlpha = 1;
const transparentAlpha = 0;

class Tooltip extends React.Component<ITooltipProps> {
  tooltipToggle: HTMLElement | null;
  tooltipContent: HTMLElement | null;

  constructor(props: ITooltipProps) {
    super(props);
    this.tooltipToggle = null;
    this.tooltipContent = null;
  }

  render() {
    return (
      <>
        {this.props.heading ? (
          <h3 className="heading-medium mbs">
            {this.props.heading}&nbsp;
            <span className="tooltip">
              <span
                className="tooltip__toggle tooltip__toggle--html"
                ref={(e) => {
                  this.tooltipToggle = e;
                }}>
                {this.props.badge && <i className="icon-question-wrapped"></i>}
                {this.props.children}
              </span>
              <span
                className="tooltip__content tooltip__content--html"
                ref={(e) => {
                  this.tooltipContent = e;
                }}>
                <h1 className="tooltip__title">{this.props.tooltipHeadline}</h1>
                <br />
                <span className="tooltip__text">{this.props.tooltipBody}</span>
              </span>
            </span>
          </h3>
        ) : (
          //could be used for a badge as tooltip eg. settings licensing or as a simple tooltip
          <span className="tooltip">
            <span
              className="tooltip__toggle tooltip__toggle--html"
              ref={(e) => {
                this.tooltipToggle = e;
              }}>
                {this.props.children}
              {this.props.badge && (<span
                className="badge"
                style={{ marginLeft: '9px', verticalAlign: 'unset' }}>
                {I18n.t('webapp.agents.edit.source_categories.print_badge')}
              </span>)}
            </span>
            <span
              className="tooltip__content tooltip__content--html"
              style={{ marginTop: '-1em' }}
              ref={(e) => {
                this.tooltipContent = e;
              }}>
              <br />
              <span className="tooltip__text">{this.props.tooltipBody}</span>
            </span>
          </span>
        )}
      </>
    );
  }

  componentDidMount() {
    const tooltipToggle = this.tooltipToggle as HTMLElement;
    (jQuery(tooltipToggle) as any).qtip(this.tooltipOptions());
  }

  componentWillUnmount() {
    const tooltipToggle = this.tooltipToggle as HTMLElement;
    (jQuery(tooltipToggle) as any).qtip("destroy");
  }

  private tooltipOptions() {
    const tooltipToggle = this.tooltipToggle as HTMLElement;

    return {
      content: jQuery(this.tooltipContent as HTMLElement),
      style: { classes: "bluereport-tooltip" },
      show: {
        effect: function () {
          jQuery(this).fadeTo(fadeInDuration, opaqueAlpha);
        },
        delay: fadeInDelay
      },
      hide: {
        effect: function () {
          jQuery(this).fadeTo(fadeOutDuration, transparentAlpha);
        },
        delay: fadeOutDelay
      },
      position: {
        my: this.props.positionMy || "bottom center",
        at: this.props.positionAt || "top center"
      }
    };
  }
}

export default Tooltip;
